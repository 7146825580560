/*! five.scss | MC2 */

/* ==========================================================================
Scaffold core 
========================================================================== */

/* ==========================================================================
0. CSS Imports
========================================================================== */

@import 'play/layout-colors';
@import 'play/themes-main';
@import 'play/layout-utils';
@import 'play/layout-navbar';
@import 'play/layout-sections';
@import 'play/layout-hero';
@import 'play/layout-footer';
@import 'play/layout-animations';
@import 'play/layout-navigation';
@import 'play/layout-pageloader';
@import 'play/components-accordion';
@import 'play/components-buttons';
@import 'play/components-cards';
@import 'play/components-boxes';
@import 'play/components-tabs';
@import 'play/components-testimonials';
@import 'play/components-dialogs';
@import 'play/components-forms';
@import 'play/components-tables';
@import 'play/components-lists';
@import 'play/components-labels';
@import 'play/components-messages';
@import 'play/components-pricing';
@import 'play/components-dropdowns';
@import 'play/extensions-checkboxes';
@import 'play/extensions-badge';
@import 'play/extensions-range';
@import 'play/extensions-ribbon';
@import 'play/extensions-slider';
@import 'play/extensions-switch';
@import 'play/extensions-timeline';
@import 'play/extensions-uploader';
@import 'play/pages';
@import 'play/pages-details';
@import 'play/pages-auth';
@import 'play/pages-about';
@import 'play/pages-landing';
@import 'play/pages-landing-features';
@import 'play/pages-landing-custom';
@import 'play/layout-helpers';
@import 'play/layout-responsive';


