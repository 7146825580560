/*! _pageloader.scss | MC2  */

/* ==========================================================================
Pageloader styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Pageloader
1. infraloader
=============================================================================
***/

/* ==========================================================================
0. Pageloader
========================================================================== */
/* // Ease Out

.pageloader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $primary; opacity: .22;
    z-index: 999998;
    transition: -webkit-transform 0.35s fade-out;
    transition: transform 0.35s ease-out;
    transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    will-change: transform;
    //Active state
    &.is-active {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    &::after, &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        border-radius: 100%;
        content: "";
        z-index: 999999;
    }

    &::after {
        margin-top: -100px;
        margin-left: -100px;
        width: 200px;
        height: 200px;
        background-color: rgba(255, 255, 255, 0.5);
        border: 3px solid rgba(255, 255, 255, 0.75);
        -webkit-animation: page-loader-inner 1.5s infinite ease-out;
        animation: page-loader-inner 1.5s infinite ease-out;
    }

    &::before {
        margin-top: -30px;
        margin-left: -30px;
        width: 60px;
        height: 60px;
        background-color: #fff;
        -webkit-animation: page-loader-outer 1.5s infinite ease-in;
        animation: page-loader-outer 1.5s infinite ease-in;
    }
}
*/

// Fade out
.pageloader {
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $primary; opacity: .22;
    z-index: 0;
    opacity:1;  /* make things visible upon start */
    -webkit-animation:fadeOut ease-in .55;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
    -moz-animation:fadeOut ease-in .55;
    animation:fadeOut ease-in .55;
    -webkit-animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
    -moz-animation-fill-mode:forwards;
    animation-fill-mode:forwards;
    -webkit-animation-duration:.55s;
    -moz-animation-duration:.55s;
    animation-duration:.55s;
    //Active state
    &.is-active {
        opacity:0;
        z-index: 999998;
        display: block;
    }

    &::after, &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        border-radius: 100%;
        content: "";
        z-index: 999999;
    }

    &::after {
        margin-top: -100px;
        margin-left: -100px;
        width: 200px;
        height: 200px;
        background-color: rgba(255, 255, 255, 0.5);
        border: 3px solid rgba(255, 255, 255, 0.75);
        -webkit-animation: page-loader-inner 1.5s infinite ease-out;
        animation: page-loader-inner 1.5s infinite ease-out;
    }

    &::before {
        margin-top: -30px;
        margin-left: -30px;
        width: 60px;
        height: 60px;
        background-color: #fff;
        -webkit-animation: page-loader-outer 1.5s infinite ease-in;
        animation: page-loader-outer 1.5s infinite ease-in;
    }
}


//loader Keyframes
@-webkit-keyframes page-loader-inner {
    0%,40% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes page-loader-inner {
    0%,40% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

@-webkit-keyframes page-loader-outer {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100%,40% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
}

@keyframes page-loader-outer {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100%,40% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
}

//fader Keyframes
@-webkit-keyframes fadeOut { from { opacity:1; } to { opacity:0; } }
@-moz-keyframes fadeOut { from { opacity:1; } to { opacity:0; } }
@keyframes fadeOut { from { opacity:1; } to { opacity:0; } }
 



/* ==========================================================================
1. Infraloader
========================================================================== */
.infraloader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $white;
    z-index: -1;
    opacity: 0;
    transition: opacity .35s;
    //Active state
    &.is-active {
        opacity: 1;
        z-index: 999997;
    }
}
