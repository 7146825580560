/*! _pages-landing-custom.scss | MC2  */

/***
TABLE OF CONTENTS
=============================================================================
0. Hero
1. Navbar
2. Titles
3. Sections
4. Layout elements
5. App Showcase
6. Team
7. Testimonials
8. Contact Form
9. Quick Signup
10. Login / Signup
11. Media Queries
=============================================================================
***/

/* ==========================================================================
0. Hero
========================================================================== */

//Hero with background image
#large-header {
    background: transparent url(../images/bg/home-banner-bg.jpg) no-repeat 0 0 / cover;
}

.navbar.is-transparent .navbar-item, .navbar.is-transparent .navbar-link {
    color: #f2f2f2;
}

.has-accent-bg {
    background: rgba(79,193,234,0.85);
    padding: 2rem .75rem;
}

@media (max-width: 768px) {
    .navbar.is-transparent .navbar-item, .navbar.is-transparent .navbar-link {
        color: #424242;
    }
}

/* ==========================================================================
1. Navbar
========================================================================== */



/* ==========================================================================
3. Sections
========================================================================== */

.section-title-wrapper .subtitle {
    padding: 20px 15% 0;
    color: #828282;
}

.container.spacer {
    min-height: 15vh;
}

.flex-card {
    flex: 1;
}

.section-accent {
    background-color: $secondary;
}

#features .card-icon img {
    max-height: 150px;
}

#features .card-title h4 {
    font-weight: 500;
}

#how-works img.narrow {
    max-width: 80%;
}
